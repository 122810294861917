@import (reference) '../../../variables';

.content {
    display: flex;
    flex-direction: column;
    & textarea {
        min-height: 400px;
    }

    @media @xs {
        margin: 0 @spaceXSmall;
    }
}

.input {
    margin: @spaceSmall 0;
}

.buttonBox {
    display: flex;
    justify-content: space-between;

    @media @xs {
        flex-direction: column;
        .button {
            margin-bottom: @spaceXSmall;
        }
    }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;