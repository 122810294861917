@import (reference) '../../../../variables';

.customFieldWrapper {
  margin-bottom: 5px;
}

.tag {
  color: @gray600;
  background-color: @white100;
  border-style: dashed;
  margin-bottom: 5px;
}

.utm {
  border-color: @colorPrimary;
}

.buttonTag {
  margin-bottom: 5px;
}

.link {
  color: @colorPrimary !important;
}

.button {
  cursor: pointer;
  color: @gray700;
  margin: 0;
  padding: 0;
  background: inherit;
  display: inherit;
  border: none;

  &:focus-visible {
    .focusIndicator;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;