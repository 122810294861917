@import (reference) '../../../../variables';

.toolbar {
  padding: @spaceXSmall @spaceSmall;
  border-top: 1px solid @gray200;
  border-bottom: 1px solid @gray200;
  // Add 40px, so toolbar covers the entire width of the container, 
  // including its padding - which is set to 20px each side. 
  // Reduce padding of applicationDetailsWrapper which is set in: 
  // candidates/CandidateWithApplications/CandidateWithApplications.less
  width: calc(100% + 40px);
  // add negative margin, so that component touches containers boarder, 
  // and is not squeezed by containers padding 
  margin: 10px -20px 0 -20px;
}

.buttonsBar {
  display: flex;
  gap: 14px;

  @media @xs {
    /* On mobile: stack buttons vertically */
    flex-direction: column;
    justify-content: center;
  }
}

.toolbarBtnContainer {
  @media @xs {
    width: 100%;
    margin-bottom: @spaceXSmall;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;