@import (reference) '../../variables';

.modalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.ant-modal) {
    position: static;
    padding: 0;
    margin: 0;
    max-width: 100vw;
  }

  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    max-height: 100vh;

    @media @xs {
      padding: @spaceSmall @spaceXSmall;
    }
  }
}

.button {
  .textSizeLarge;
  .standardMotion;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: @spaceXSmall;
  color: @colorPrimary;
  padding: 0;

  &:hover {
    color: @colorActive;
  }

  &:focus-visible {
    outline: 2px solid white;
  }
}

.frame {
  border: none;
  height: 100%;
  padding: 30px;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;