@import (reference) '../../../variables';

.container {
  text-align: center;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-gap: @spaceSmall;
  justify-items: center;
}

.illustration {
  width: 100%;
  max-width: 180px;
  margin-top: 100px;

  @media @sm {
    margin-top: 0;
  }
}

.header {
  .h3Size;
  color: @gray500;
  font-weight: 200;
  line-height: 1.2;
  width: 80%;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;