@import (reference) '../../variables';

.breadcrumbs {
  flex: 1;
}

.lastBreadcrumb {
  font-weight: bold;
}

.dropdown {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 @spaceXSmall;
  border: none;
  background: inherit;
  padding: 0;

  &:hover {
    background: @colorActiveLight;
  }

  &:focus-visible {
    .focusIndicator;
  }
}

.menuItem {

    &:focus-visible {
      .focusIndicator;     
    }
}

.homeIcon {
  font-size: 16px !important;
}

.dropdownHomeIcon {
  margin-right: 5px;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;