@import (reference) '../../../../variables';

.field {
  margin-top: -7px;
}

.input {
  width: 100px;
  display: inline-block;
  margin-right: 3px;
}

.select {
  min-width: 100px;
  display: inline-block;
  margin-right: 3px;
}

.icon {
  padding-right: 5px;
  font-size: 10px;
}

.buttonDelete {
  height: 0;
  border: none;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    color: @gray500;
  }

  &:focus-visible {
    .focusIndicator;
    height: 100%;
  }
}

// make a space between focused custom choice tag and rest of tags. It is same space as next to the focused text tag
.customChoiceTagWrapperFocused {
  margin-right: 16px;
}

// recreating the .ant-input-focused class to imitate the behavior of focused text tag
.customChoiceTagFocused {
  border: 1px solid #b877a8;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(170 83 151 ~'/ 20%');
  background-color: white !important;
  color: @text-color !important;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;