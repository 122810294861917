@import (reference) '../../variables';

.welcomeMessage {
  text-align: center;
  margin-bottom: @space;
}

.welcomeTitle {
  .h2Size;
  margin-bottom: @spaceXSmall;
  font-weight: 200;
  line-height: 1.2;
}

.welcomeSubtitle {
  .textSizeLarge;
  color: @colorTextMuted;
  margin-bottom: 0;
  max-width: @contentWidthSmall;
}

.introScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  margin: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;