@import (reference) '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.mainPoints {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainPointsBox {
    display: flex;
    flex-wrap: wrap;
    gap: @spaceXSmall;

    @media @xs {
      justify-content: center;
    }
}

.input {
    margin: @spaceXSmall 0 @spaceSmall 0;
    width: 400px;

    @media @xs {
        width: 90%;
    }
}

.mainPointsTitle {
    font-weight: 500;
    text-align: center;
    margin: @spaceXSmall 0 @spaceXSmall;
}

.iconWrapper {
    cursor: move;
    transition: all 0.3s @standardMotion;
    position: relative;

    &:hover {
        transform: scale(1.05);
    }

    &.dragging {
        opacity: 0.5;
    }

    &.placeholder {
        opacity: 0.7;
        background-color: @colorBackgroundHighlight;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
        border-radius: 4px;
    }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;