@import (reference) '../../variables';

.infoBoxTopRow {
  .h5Size;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.infoBoxTopRowPrice {
  margin-left: @spaceXSmall;
  color: @colorTextMuted;
}

.infoBoxBottomRow {
  margin-top: @space;
  color: @colorTextSecondary;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media @sm {
    flex-direction: column;
  }

  dl {
    &:first-child {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
      margin: 0 @space;
    }

    &:last-child {
      text-align: right;
    }

    @media @sm {
      text-align: left !important;

      &:not(:last-child) {
        margin: 0 0 @spaceSmall;
      }
    }
  }

  dd {
    margin-top: 5px;
  }
}

.currentUsagePositions {
  display: flex;
  justify-content: space-between;
}

.progressBarWrapper {
  display: flex;
  justify-content: center;
}

.progressLegend {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: @spaceXSmall;

  @media @sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.progressLegendItem {
  margin: 0 @spaceSmall;

  @media @sm {
    margin: 0 0 @spaceXSmall;
  }
}

.statusBadge {
  margin-right: 5px;
}

.currentUsageDetails {
  margin-top: @spaceLarge;
}

.currentUsageButtonWrapper {
  text-align: center;
}

.currentUsageButton {
  border: none;
  color: @colorPrimary;
  box-shadow: none;
}

.currentUsageDetailsList {
  padding-left: 0;
  list-style: none;
  margin-top: @spaceXSmall;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &Muted {
    color: @colorTextMuted;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;