@import (reference) '../../variables';

.button:global(.ant-btn) {
  line-height: 1.499;
  &:focus-visible {
    .focusIndicator;
  }

  &.iconOnly {
    border: none;
    border-radius: 0;
    padding: 0;
    height: auto;
    width: auto;

    &:focus-visible {
      .focusIndicator;
    }
  }
}

.hidden {
  display: none;
}

.fullWidth:global(.ant-btn) {
  width: 100%;
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;