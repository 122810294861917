@import (reference) '../../variables';

@colorSeen: #c2c2c2;
@colorUnseen: @colorPrimary;

.activity {
  // overwriting ant-timeline-item class from AntD's to align its elements
  // and to make them easier to edit
  display: flex;
  align-items: flex-start;
  padding: 8px;
  margin-bottom: 1px;

  :global(.ant-timeline-item-content) {
    width: 100%;
    margin-left: 5px;
    // overwriting position relative from AntD's default class
    position: unset;
  }

  :global(.ant-timeline-item-head) {
    margin: 3px;
    width: 15px;
    height: 15px;
    border-color: @bg;
    background: @colorSeen;
    color: @colorTextMuted;
    // overwriting position absolute from AntD's custom class
    position: unset;
    // overwriting translate(-50%, -50%) from AntD's custom class
    transform: unset;
  }

  // changing top and left in AntD's class to align tail properly
  :global(.ant-timeline-item-tail) {
    top: 34px;
    left: 17px;
    height: calc(100% - 26px);
  }

  // adding margin-top to AntD's class to align avatar with text
  :global(.ant-avatar-circle) {
    margin-top: -2.5px;
  }

  p {
    margin-bottom: 0;
  }

  pre {
    color: @colorTextSecondary;
    border-top: 1px solid @gray200;
    border-bottom: 1px solid @gray200;
    background: @gray100;
    padding: @spaceXSmall;
    font-size: 12px;
    margin: @spaceXSmall 0;
  }

  &:hover .iconEdit {
    visibility: visible;
  }
}

.withIcon {
  :global(.ant-timeline-item-head) {
    box-sizing: content-box;
    padding: 0;
    border-radius: 50%;
    background: @bg;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.highlighted {
  background-color: @purple100;

  // adding a border to after, as the border added directly to li affects the width
  // and causes icons from unread and read activities to be misaligned
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-left: 3px solid #aa5397;
    height: 100%;
  }

  :global(.ant-timeline-item-head) {
    color: @colorUnseen;
    background: transparent;
    border-color: @purple100;
  }
}

.unseenActivity {
  .header {
    background-color: @colorBackgroundHighlight;
    border-radius: 5px;
    padding: 5px;
  }

  :global(.ant-timeline-item-head) {
    background: @colorUnseen;
    color: @colorUnseen;
    border-color: @bg;
  }

  &.withIcon :global(.ant-timeline-item-head) {
    background: @bg;
  }
}

.withExcerpt {
  .detailsContent {
    padding-top: 5px;
  }
}

.detailsContent {
  width: 100%;
  color: @colorText;
  font-size: 13px;
  transition: color 200ms @standardMotion;
  word-break: break-word;
  border: none;
  padding: 0;
  background-color: transparent;
  white-space: normal;
  text-align: left;
  height: auto;
  outline: none;
  user-select: text;
  
  &:focus-visible {
    .focusIndicator;
  }
}
.expanded {
  &.expandable {
    .details::after {
      height: 0;
      content: '';
      transition: height 200ms @standardMotion;
    }

    // reset to initial when the text content is expanded, so br's are displayed normally
    .detailsContent br {
      display: unset;
    }

    .detailsWrapper {
      box-shadow: 0 5px 20px @colorShadowLight;
    }
  }
}

.header {
  .textSizeSmall;
  color: @colorTextSecondary;
  display: inline-block;

  &:hover {
    color: @colorTextSecondary;
  }

  b {
    font-weight: 500;
  }

  a {
    font-weight: 600;
    color: @colorText;

    &:hover {
      color: @colorPrimary;
    }
  }
}

.date {
  .textSizeSmall;
  color: @colorTextMuted;
}

.username {
  font-weight: 600;
  margin-left: 5px;
}

.detailsWrapper {
  transition: all 200ms @standardMotion;
  border: 1px solid @colorLightGray;
  border-radius: 3px;
  margin-left: 0;
  margin-top: 7px;
  padding: @spaceXSmall @spaceSmall;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.detailsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable {
  .detailsContent {
    cursor: pointer;
    // when content is collapsed, we want to remove br's so the text is displayed inline and
    // the excerpt is easier to read
    br {
      display: none;
    }
  }

  .details::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 120%
    );
    pointer-events: none;
    transition: height 200ms @standardMotion;
  }
}

.excerpt {
  color: @colorTextSecondary;

  strong {
    font-weight: 600;
  }

  &:hover,
  &:active,
  &:focus {
    color: @colorTextSecondary;
    background-color: transparent;
  }
}

.details {
  transition: height 250ms @standardMotion;
  overflow: hidden;
  position: relative;
}

.detailsToolbar {
  position: absolute;
  right: 15px;
  top: -12px;
}

.attachments {
  margin: 15px 0 0 -10px;
  font-size: 10px;
}

.fileIcon {
  margin-right: 5px;
}

.link {
  &:focus-visible {
    .focusIndicator;
  }
}

.iconEdit {
  visibility: hidden;
  margin-left: 5px;

  &:hover {
    color: @colorPrimary;
  }
}

.withToolbar {
  // add display: none to hide an activity. For example when a user clicks the "delete the notification",
  // but before the deleting mutation is sent. When a user clicks "restore the notification",
  // this class is removed.
  &.activity {
    &.hide {
      display: none;
    }
  }

  .toolbar {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  // add margin-right to make space for the toolbar
  .header {
    margin-right: 52px;
  }
}

@media only screen and (max-device-width: 768px) {
  .iconEdit {
    visibility: visible;
    margin-left: 5px;

    &:hover {
      color: @colorPrimary;
    }
  }
}

@media (pointer: fine) {
  .detailsToolbar {
    opacity: 0;
    transition: all 150ms @standardMotion;
  }

  .detailsWrapper:hover .detailsToolbar {
    opacity: 1;
  }
  
  .detailsToolbar {
    &:focus-within{
      opacity: 1;
    } 
  }
  
  .withToolbar {
    .toolbar {
      opacity: 0;
      transition: all 150ms @standardMotion;
    }

    &.activity:hover .toolbar {
      opacity: 1;
    }
  }
}

// changing the height and width of the icon to align icon with the text height (12px) on a mobile
@media @sm {
  .withIcon {
    :global(.ant-timeline-item-head) {
      width: 12px;
      height: 12px;
    }
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;