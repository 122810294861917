@import (reference) '../../../variables';

.detailsView {
  margin: -@space;
  min-height: ~'calc(100vh - 130px)';
  display: flex;

  &.inDrawer {
    min-height: 100vh;
    min-width: 100%;
    margin: 0;
  }

  @media @md {
    flex-direction: column;
  }

  @media @xs {
    margin: -@spaceXSmall;
  }
}

.candidateDetailsWrapper {
  max-width: 800px;
  background-color: @colorBackgroundLightGray;
  border-left: 0px !important;
  flex: 0.9;
  padding: @space @spaceSmall;
  z-index: 0;

  &.noApplication {
    max-width: 100%;
    flex: 1;
  }

  @media @md {
    flex: inherit;
    max-width: 100%;
    padding-bottom: 0;
  }

  @media @xs {
    padding-top: @spaceSmall;
  }
}

.applicationDetailsWrapper {
  flex: 1;
  background-color: @colorBackgroundLightGray;
  border-left: 1px solid @colorLightGray;
  padding: @space @spaceSmall;

  @media @xs {
    padding-top: @spaceSmall;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;