@import (reference) '../../variables';

@keyframes spin {
  from {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-15px);
    animation-timing-function: @standardMotion;
  }
  50% {
    transform: translateY(5px);
    animation-timing-function: @standardMotion;
  }
}

.aiLoaderContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.smallLoaderContainer {
  padding: @spaceXSmall 0;
}

.defaultLoaderContainer {
  padding: @spaceSmall 0;
}

.largeLoaderContainer {
  padding: @space 0;
}

.fullSizeWrapper {
  width: 100%;
  text-align: center;
}

.robotWrapper {
  position: relative;
  transition: transform;
  padding: @spaceXSmall;

  &.bouncing {
    animation: bounce 1.2s @standardMotion infinite;
  }
}

.spinning {
  animation: spin 2s @standardMotion infinite;
  color: @orchid800;
}

.message {
  margin-top: @spaceSmall;
  color: @colorTextMuted;
  .textSize();
  .standardMotion();
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;