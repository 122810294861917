@import (reference) '../../variables';

.radioTransparent {
  :global {
    .ant-radio-button-wrapper {
      background-color: transparent;
      border: none;
      border-radius: 6px;
      color: white;

      &::before {
        display: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.ant-radio-button-wrapper-checked {
        background-color: rgba(255, 255, 255, 0.1);
        box-shadow: none;
        border: 0;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.radio {
  :global(.ant-radio-button) {
    &:focus-within {
      outline: 2px solid @purple800;
      outline-offset: 2px;
    }
  }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;