@import (reference) '../../../../variables';

@colorButtonInactive: @colorShadow;

.contact {
  margin-top: @space;

  @media @xs {
    margin-top: @spaceSmall;
  }
}

.contactWrapper {
  margin-top: @spaceXSmall;
  display: flex;
  flex-wrap: wrap;
}

.sectionTitle {
  .h5Size;
  display: flex;
  align-items: center;
  margin-bottom: @spaceXSmall;
}

.sectionTitleText {
  margin-right: @spaceXSmall;
  color: @colorTextMuted;
}

.addButton {
  color: @colorButtonInactive;
  cursor: pointer;
  .standardMotion;

  &:hover {
    color: @colorPrimary;
  }
}

.form {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.formField {
  max-width: 150px;

  :global(.ant-input) {
    .textSizeSmall;
  }
}

.formBtn {
  .h4Size;
  .standardMotion;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 5px;

  &:focus-visible {
    .focusIndicator;
  }
}

.submitBtn {
  &:hover {
    color: @colorPrimary;
  }
}

.cancelBtn {
  &:hover {
    color: @invertedErrorColor;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;