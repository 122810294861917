@import (reference) '../../../variables';

@fieldErrorTextMargin: 19px;

.applicationDetails {
  max-width: @contentWidthLarge;

  @media @xl {
    margin: auto;
  }
}

.applicationHeader {
  max-width: 100%;
}

.applicationPosition {
  .h4Size;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notLink {
  .removeLinkStyling;
}

.applicationOptions {
  .textSizeLarge;
  cursor: pointer;
  .standardMotion;
  margin-left: @spaceXSmall;

  &:hover {
    color: @colorPrimary;
  }
}

.applicationHeaderInfo {
  .textSizeSmall;
  color: @colorTextMuted;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.applicationLocation {
  margin-right: @spaceXSmall;
}

.customFieldsContainer {
  margin-top: @spaceXSmall;
}

.alertWrapper {
  margin-top: @spaceSmall;
}

.applicationCandidateInfo {
  display: flex;
  flex-wrap: wrap;
  margin: @space 0;
  justify-content: space-between;
}

.selectContainer {
  margin-bottom: @spaceXSmall;
}

.applicationSubsectionTitle {
  .textSizeSmall;
  color: @colorTextMuted;
}

.inputs {
  margin-top: @space;
}

.noUserEmail {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px @spaceSmall;

  :global(.ant-alert-icon) {
    position: initial;
    margin-right: @spaceXSmall;
  }
}

.emailSelect {
  margin-bottom: @space + @fieldErrorTextMargin;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selectLabel {
  font-size: 90%;
  color: @colorText;
  opacity: 0.7;
}

.templateSelect {
  margin-top: 5px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.infoIcon {
  color: @colorPrimary;
  .standardMotion;

  &:hover {
    color: @colorActiveDark;
  }
}

.emailSubjectWrapper {
  margin-bottom: @space + @fieldErrorTextMargin;

  &.withError {
    margin-bottom: @space;
  }
}

.button {
  float: right;
}

.utmInfoModal {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rejectionAlert {
  margin-bottom: @space;
}

.rejectedContent {
  opacity: 0.6;
  pointer-events: none;
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;