@import (reference) '../../../variables';

.container {
  max-width: @contentWidthSmall;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.VASwrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
}

.submitBtn {
  align-self: flex-end;
  margin-top: @spaceSmall;
  padding: 0 @space;
}

.checkbox {
  margin: 0 0 @spaceXSmall!important;

  &:last-child {
    margin-bottom: 0;
  }
}

.item {
  display: flex;
  margin-bottom: 15px;
}

.itemName {
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.itemDescritption {
  margin-left: 10px;
}

.link {
  &:focus-visible {
    .focusIndicator;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;