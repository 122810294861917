@import (reference) '../../variables';

.table {
    :global(.ant-table-tbody > tr.ant-table-placeholder) {
        color: @textLight;
    }

    :global(.ant-table table) {
        border-collapse: collapse;
    }

    :global(.ant-table-tbody > tr.ant-table-row-selected) {
        border-left: 3px solid @orchid800;

        &>td {
            background: @tableSelectedRow;
        }
    }

    :global(.ant-pagination-item) {
        &:focus-visible {
            .focusIndicator;
        }
    }

    :global(.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) ) {
        &:focus-visible {
            .focusIndicator;
        }
    }
}

.arrowButton {
    border: 1px solid @antdBorder;
    border-radius: 6px;
    font-size: 12px;
    height: 100%;
    width: 100%;
    background-color: inherit;
    color: @text-color;

    &:hover {
        border: 1px solid @colorPrimary;
        color: @colorPrimary;
    }

    &:disabled {
        cursor: not-allowed;
        color: @antDisabled;

        &:hover {
            border-color: @antdBorder;
        }
    }

    &.small {
        border: none;
    }

    &:focus-visible {
        .focusIndicator;
    }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;