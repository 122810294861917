@import (reference) '../../../../variables';

.container {
  display: flex;
  align-items: center;
  margin-right: 25px;
  margin-bottom: 5px;
  max-width: 300px;
  .standardMotion;

  &:hover {
    margin-right: 10px;

    .deleteIcon {
      margin-left: 5px;
      opacity: 1;
    }
  }

  @media @md {
    width: 100%;
    margin-right: 10px;
  }
}

.content {
  margin-left: 5px;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typeIcon {
  .textSizeSmall;
  color: @colorDarkGray;
}

.deleteButton {
  border: none;
  background: inherit;

  &:focus-visible {
    margin-right: 10px;
    margin-left: 5px;
    opacity: 1;
    .focusIndicator;

      .deleteIcon {
        margin-left: 0;
        opacity: 1;
      }
  }
}

.deleteIcon {
  margin-left: -10px;
  opacity: 0;
  cursor: pointer;
  .standardMotion;

  @media @md {
    margin-left: 5px;
    opacity: 1;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;