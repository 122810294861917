@import (reference) '../../../../variables';

.container {
  .authenticationBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media @sm {
    padding: 0 @spaceSmall;
  }

  &.containerInSettings {
    background: @bg;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    align-items: flex-start;

    @media @sm {
      padding: 0;
    }
  }
}

.content {
  width: @contentWidthSmall;
  max-width: 100%;
  margin: auto;

  &.contentInSettings {
    margin: 0;
  }

  :global(.ant-row.ant-form-item) {
    margin-bottom: 32px;
  }

  :global(.ant-row.ant-form-item.ant-form-item-with-help) {
    margin-bottom: 13px;
  }
}

.title {
  .textSizeLarge;
  color: @colorTextAlt;
  margin-bottom: @space;
}

.form {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.skipOrLogout {
  .textSizeLarge !important;
  color: @colorTextAlt;

  @media @xs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.logout {
  color: @white;
  margin-left: @spaceXSmall;

  @media @xs {
    margin-top: @spaceSmall;
  }
}

.logo {
  width: 150px;
  margin-bottom: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;