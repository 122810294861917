@import (reference) '../../../variables';

@siderText: #acb4bd;
@siderTextLight: #fff;
@dropdownDivider: rgba(200, 200, 200, 0.2);

.apla {
  position: absolute;
}

.divider {
  background-color: @bgAltLight !important;
  margin: @spaceXSmall @spaceSmall !important;
}

.layout {
  min-height: 100vh;
}

.drawer {
  min-height: 100vh;
}

.sider {
  min-height: 100vh;
  z-index: 1;
  position: relative;
  box-shadow: 0px 10px 30px #69306D26;

  :global(.ant-menu-item-selected) {
    color: @purple800;
    background-color: @purple100 !important;

    &:hover {
      color: @purple800;
      background-color: @purple100;
    }

    &:focus {
      color: @purple800 !important;
      background-color: @purple100 !important;
    }
  }
}

.mainMenu {
  :global(.ant-menu-item ) {
    &:focus-visible {
      .focusIndicator;
    }
  }

  :global(.ant-menu-submenu-title) {
    &:focus-visible {
      .focusIndicator;
    }
  }
}

.orgContainer {
  padding: 0 @spaceXSmall;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @siderText;
  height: 65px;
  cursor: pointer;
  outline: none;
  opacity: 1;
}

.logo {
  width: 150px;
}


.contentWrapper {
  padding: @space;
  overflow: auto;
  position: relative;
  background-color: @bg;

  @media @lg {
    padding: @spaceSmall;
  }

  @media @xs {
    padding: @spaceXSmall 0;
  }
}

.footer {
  text-align: center;
  color: @colorHint;
  font-size: @sizeHint;
  background-color: @bg;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;