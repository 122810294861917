@import (reference) '../../../../variables';

@colorButtonInactive: @colorShadow;

.applications {
  margin-top: @space;
  margin-right: -@spaceSmall;

  @media @lg {
    display: flex;
    flex-direction: column;
  }

  @media @xs {
    margin-top: 15px;
  }
}

.sectionTitle {
  .h5Size;
  display: flex;
  align-items: center;
  margin-bottom: @spaceXSmall;
}

.sectionTitleText {
  margin-right: @spaceXSmall;
  color: @colorTextMuted;
}

.addButton {
  color: @colorButtonInactive;
  cursor: pointer;
  .standardMotion;

  &:hover {
    color: @colorPrimary;
  }
}

.tabs {
  width: 100%;
  display: inline-block;

  :global(.ant-tabs-bar) {
    background-color: transparent !important;
    width: 100%;
    border-right: 0px !important;
    padding: 0 !important;

    @media @lg {
      padding: @spaceXSmall 0 0;
      margin-bottom: 0;
    }
  }

  :global(.ant-tabs-nav-container) {
    z-index: 2;
    margin-right: 0px !important;
  }

  :global(.ant-tabs-tab) {
    padding: @spaceXSmall @spaceSmall;
  }

  :global(.ant-tabs-tab.ant-tabs-tab-active) {
    background-color: @purple100;

    .tabName {
      color: @purple800;
    }

    .tabDate {
      color: darken(@colorTextMuted, 10%);
    }
  }

  :global(.ant-tabs-content) {
    display: none;
  }

  :global(.ant-tabs-tab-btn) {
    width: 100%;
  }

  :global(.ant-tabs-tab) {
    cursor: default;
  }
}

.tabContainer {
  width: 100%;
  text-align: left;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  position: relative;

  @media @lg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tabName {
    color: initial;

    &:hover {
      color: @colorActive;
    }
  }

  .tabInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: @colorText;
  }
}

.rejectedTab {
  opacity: 0.5;

  .tabName {
    text-decoration: line-through;
  }
}

.restrictedTabContainer {
  width: 100%;
  text-align: left;
  white-space: normal;
  display: block;
  text-decoration: none !important;

  @media @lg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  color: @gray500;

  .tabName {
    color: @gray500;
  }

  .tabInfo {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: @gray500;
  }
}

.tabRating {
  .textSizeSmall;
  margin-right: @spaceXSmall;
  min-width: 140px;

  @media @xs {
    margin-bottom: @spaceXSmall;
    flex: 1;
  }
}

.tabStage {
  .textSizeSmall;
  margin-right: @spaceXSmall;
  min-width: 120px;

  @media @xs {
    margin-bottom: @spaceXSmall;
    flex: 1;
  }
}

.tabDate {
  .textSizeSmall;
  min-width: 120px;
  color: @colorTextMuted;

  @media @xs {
    .textSizeSmall;
    margin-bottom: @spaceXSmall;
    flex: 1;
  }
}

.tabPane {
  width: 100%;
}

.tabContent {
  flex: 1;
  min-width: 0;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;