@import (reference) '../../variables';

.tableWrapper {
  // Allow scrolling when table is wider than its container
  overflow-x: auto;
}

.table {
  // Table should always fill its container whole
  min-width: 100%;


  th {
    max-width: 150px;
    word-wrap: break-word;
  }

  tr:nth-child(even) {
    background-color: @colorBackgroundGray;
  }

  td {
    padding: 10px 0 10px;
  }

  .permissionColumn{
    padding-left: 10px;
  }
}

.permissionCheckbox {
  text-align: center;
  color: @colorDanger;
}

.iconHasPermission {
  color: @colorPrimary;
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;