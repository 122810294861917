@import (reference) '../../../variables';

.showMore {
  color: @colorPrimary;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
}

.alertDetails {
  .textSize;
  margin-top: @spaceXSmall;
}

.alertList {
  margin: @spaceSmall 0 @spaceXSmall;
  padding-left: 0;
  list-style: none;

  @media @xs {
    padding-left: @spaceSmall;
  }
}

.alertItem {
  max-width: @contentWidthLarge;

  &:not(:last-child) {
    border-bottom: 0.5px inset @colorLightGray;
    padding-bottom: @spaceXSmall;
    margin-bottom: @spaceXSmall;
  }
}

.alertItemContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.alertItemContentText {
  margin-right: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;