@import (reference) '../../../variables';

.arrow {
  vertical-align: middle;
  margin-left: @spaceXSmall;
}

.header {
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px @colorShadow;
  z-index: 1;
  position: relative;

  &:global(.ant-layout-header) {
    padding: 0 @space;

    @media @xs {
      padding: 0 @spaceXSmall;
    }
  }

  @media @xs {
    display: flex;
    justify-content: space-between;
  }
}

.userMenu {
  cursor: pointer;
  border: none;
  background: inherit;
  padding: 0;

  &:focus-visible {
    .focusIndicator;
  }

  @media @xs {
    float: right;

    .loginMenu {
      display: flex;
      align-items: center;
    }
  }
}

.userName {
  margin-left: 10px;
}

.menuTrigger {
  color: @colorText;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  font-weight: bold;

  &:hover {
    color: @colorPrimary;
  }

  &:focus-visible {
    .focusIndicator;
  }
}

.menuTriggerText {
  @media @xs {
    display: none;
  }
}

.menuTriggerIcon {
  margin-right: @spaceXSmall;
  font-size: 20px;
  font-weight: normal;
}

.menuTriggerDivider {
  border-right: 1px solid @colorBorder;
  height: 35px;
  margin: 0 @spaceSmall;

  @media @xs {
    display: none;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;