@import (reference) '../../../variables';

.dateWrapper {
  .textSizeSmall;
}

.templateWrapper {
  .tileContentWrapper;
  margin-bottom: 30px;
}

.tableTitle {
  .textSizeLarge;
  font-weight: bold;
  margin: 0 0 10px 10px;
}

.table {
  box-shadow: 0px 2px 4px @colorShadowLight;
}

.locked {
  color: @textLight;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;