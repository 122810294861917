@import (reference) '../../../../variables';

.wrapper {
  width: @contentWidthSmall;
  max-width: 100%;
}

.managerAlert {
  margin-bottom: @spaceSmall;
}

.infoRequiredText {
  .textSize;
  .helpText;
}

.form {
  width: 100%;
  margin-top: @space;

  :global(.ant-form-item) {
    margin-bottom: 30px;
  }

  :global(.ant-form-item-with-help) {
    margin-bottom: 11px;
  }
}

.orgInfo {
  .textSize;

  .mutedText {
    .textSizeSmall;
    color: @colorTextMuted;
    margin-top: @spaceXSmall;
  }
}

.submitBtn {
  float: right;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;