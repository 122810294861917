@import (reference) '../../variables';

.arrow {
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;

  &.inverted {
    &:before,
    &:after {
      background: white;
    }
  }

  &.active {
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(135deg);
    }
  }

  &:before,
  &:after {
    .standardMotion;
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    background: @colorTextMuted;
    border-radius: 2px;
    transform: rotate(-45deg);
    transform-origin: 1px 1px;
    position: absolute;
    top: 8px;
    left: 7.5px;
  }

  &:after {
    transform: rotate(225deg);
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;