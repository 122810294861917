@import (reference) '../../../variables';

.buttonSecondary {
  margin-right: 10px;
  
  @media @sm {
    margin-right: 5px;
  }

  &:disabled {
    pointer-events: none;
  }
}

.searchInput {
  width: 320px;
  @media @sm {
    width: 100%
  }
}

.filtersBox {
  margin: @spaceSmall 0;
}

.filtersHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: @spaceXSmall;
}

.filtersTitle {
  .standardMotion;
  cursor: pointer;
  color: @colorPrimary;

  &:hover {
    color: @colorActive;
  }
}

.filtersArrow {
  .standardMotion;

  &.arrowActive {
    transform: rotateX(0.5turn);
  }
}

.hideFiltersButton {
  margin-left: @spaceXSmall;
}

.filter {
  flex: 1;
}

.searchFilterBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 5px 0;
}

.fetchMoreLoading {
  .h4Size;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.candidatesCount {
  .textSizeSmall;
  color: @colorTextMuted;
  margin: @spaceXSmall 0;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;