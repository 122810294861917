@import (reference) '../../../variables';

.container {
  display: flex;
  align-items: center;

  &.containerCreated {
    margin: @space @spaceSmall;
    align-items: flex-start
  }
}

.avatarWrapper {
  width: 40px;
}

.headerText {
  margin-left: @spaceXSmall;
  flex: 1;
}

.headerNameWithBtns {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.headerName {
  .h4Size;

  &.notLink {
    .removeLinkStyling;
  }
}

.candidateOptions {
  .textSizeLarge;
  .standardMotion;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: @colorPrimary;
  }
}

.headerInfo {
  .textSizeSmall;
  color: @colorTextMuted;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  > span {
    margin-right: @spaceSmall;

    &:last-child {
      margin-right: 0;
    }
  }
}

.form {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: @spaceXSmall;
}

.formField {
  top: 10px;
  :global(.ant-input) {
    .textSizeSmall;
  }
}

.firstNameField {
  margin-right: @spaceXSmall;
}

.formBtn {
  .h4Size;
  .standardMotion;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus-visible {
    .focusIndicator;
  }
}

.submitBtn {
  &:hover {
    color: @colorPrimary;
  }
}

.cancelBtn {
  &:hover {
    color: @invertedErrorColor;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;