@import (reference) '../../variables';

.select {
  .textSizeSmall;

  &.noBorders {
    :global(.ant-select-selector) {
      border: none;
    }

    &:focus-within {
      outline: 2px solid lighten(@colorPrimary, 40%);
      border: 1px solid @colorPrimary;
      border-radius: 5px;
    }
  }
  :global(.ant-select-selection-placeholder) {
    color: @colorTextMuted;
  }
}

.dropdown {
  .textSizeSmall;
}


:global(.ant-select-item-option-active) {
  outline: none;
  border: 2px solid @purple800;
}


.fullWidth {
  width: 100%;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;