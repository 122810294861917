@import (reference) '../../variables';

.tileBtn {
    width: 165px;
    height: 110px;
    padding: @spaceXSmall;
    margin-right: @spaceXSmall;
    background: @backgroundLight;
    color: @colorTextSecondary;
    border: 2px solid @colorTextSecondary;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: @colorPrimary;
        border: 2px solid @colorPrimary;
    }
}

.shortBtn {
    height: 32px;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;