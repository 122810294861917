@import (reference) '../../../../variables';

.title {
  margin-bottom: 5px;
  padding: 2px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: @purple100;

    .closeButton,
    .checkbox,
    .iconMail {
      display: flex;
    }
  }

  &:focus-within {
    .closeButton,
    .checkbox,
    .iconMail {
      display: flex;
    }
  }
}

.tooltipText {
  display: flex;
  flex-direction: column;
}

.icon {
  margin-right: 7px;
  color: @gray600;
}

.iconMail {
  display: none;
  margin: 0 15px;
  color: @gray600;
}

.closeButton {
  display: none;
  float: right;
  padding: 5px;
  font-size: 10px;
  color: @gray600;
  background: inherit;
  border: none;

  &:focus-visible {
    .focusIndicator;
  }
}

.link {
  line-height: 1.5px;
  padding-right: auto;

  &:focus-visible {
    .focusIndicator;
  }
}

.checkbox {
  display: none;
  margin: 0 20px;
  color: @gray500;

  &:focus-within {
    .focusIndicator;
  }
}
.checked {
  color: @gray700;
}

@media @xs {
  .checkbox,
  .closeButton {
    display: flex;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;