@import (reference) '../../../variables';

@signUpBoxBorderColor: @colorTextAltMuted;

.container {
  .textSize;
  .authenticationBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global(.ant-row.ant-form-item) {
    margin-bottom: 35px;
  }

  :global(.ant-row.ant-form-item.ant-form-item-with-help) {
    margin-bottom: @spaceXSmall;
  }

  :global(.ant-form-explain) {
    margin-top: 4px;
  }

  :global(.has-error .ant-form-explain) {
    color: @invertedErrorColor;
  }
}

.invalidInvitation {
  .h4Size;
  margin: auto;
  color: @colorTextAlt;
  text-align: center;
}

.content {
  width: 600px;
  max-width: 100%;
  margin: auto;
  text-align: center;

  @media @xs {
    margin: @spaceXSmall 0;
  }
}

.loginTitle {
  .h2Size;
  text-align: left;
  color: @colorTextAlt;
  margin: 0;
  padding: 0 @space;
  font-weight: 300;
  margin-bottom: @space;

  @media @xs {
    text-align: center;
    padding: 0 @spaceSmall;
  }
}

.formContainer {
  padding: 0 @space;
  margin: @spaceSmall 0 @spaceLarge;

  @media @xs {
    padding: 0 @spaceSmall;
  }
}

.checkbox {
  color: @colorTextAlt;
  line-height: 1.5;
}

.formActionBox {
  .textSize;
  padding: 0 @spaceXSmall;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backToLogin {
  color: @colorTextAltMuted;
  width: 80%;
  text-align: center;
  box-shadow: 0 0 2px @signUpBoxBorderColor;
  margin: 0 auto;
  padding: 15px @spaceXSmall;
  border-radius: 5px;
}

.termsLink {
  color: @yellow; 

  &:focus-visible {
    outline: 2px solid white;
  }
}

.logInLink {
  margin-left: 0.5em;
  color: @yellow;

  &:focus-visible {
    outline: 2px solid white;
  }
}

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.registerBtn {
  padding: 0 @spaceLarge;

  &:focus-visible {
    outline: 2px solid white !important;
    outline-offset: 2px;
  }
}

.logo {
  width: 150px;
  margin-bottom: @spaceXSmall;
  color: @white;
}

.alert {
  margin-bottom: @space;
}

.invitationWelcomeText {
  .textSizeLarge;
  color: @colorTextAltMuted;
  line-height: 1.5;
  text-align: left;
  margin-bottom: @spaceLarge;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;