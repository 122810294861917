@import (reference) '../../variables';

@colorButtonInactive: @colorShadow;

.container {
  display: flex;
  flex-direction: column;
}

.invite {
  margin-top: @space;
}

:global(form .ant-select).roleSelect {
  width: 280px;

  @media @xs {
    margin-bottom: @spaceXSmall;
  }
}

.email {
  min-width: 250px;
  margin-bottom: @spaceXSmall;

  @media @sm {
    min-width: 280px;
  }
}

.field {
  @media @xs {
    display: block !important; // overwrite default's AntD style
  }
}

.icon {
  font-size: 18px;
  cursor: pointer;
  .standardMotion;

  &.iconEdit {
    margin-right: 15px;

    &:hover {
      color: @colorPrimary !important;
    }
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;