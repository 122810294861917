@import (reference) '../../variables';

.container {
  margin-bottom: @spaceSmall;
  max-width: 100%;
  display: flex;
  align-items: center;

  @media @xs {
    justify-content: space-between;
  }
}

.email {
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  text-overflow: ellipsis;

  &:focus-visible {
    .focusIndicator;
  }
}

.textWrapper {
  max-width: ~'calc(100% - 50px)';

  @media @xs {
    flex: 1;
  }
}

.deleteIcon {
  .h5Size;
  .standardMotion;
  cursor: pointer;
  margin-left: @spaceXSmall;

  &:hover {
    color: @invertedErrorColor;
  }

  @media @xs {
    .h4Size;
    margin: 0 @spaceXSmall 0 @spaceSmall;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;