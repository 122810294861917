@import (reference) '../../variables';

.tile {
    width: 140px;
    height: 110px;
    margin: 0 @spaceXSmall @spaceXSmall 0;
    background: @backgroundLight;
    color: @textLight;
    border: 1px solid @colorShadowLight;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        .removeBtn {
            opacity: 1;
        }
    }
}

.removeBtn {
    background: transparent;
    margin-bottom: @spaceXSmall;
    opacity: 0;
    position: absolute;
    top: @spaceXSmall;
    right: @spaceXSmall;

    &:hover {
        background: transparent;
    }
}

.iconBtn {
    border: none;
    background: inherit;
    cursor: pointer;

    &:focus-visible {
        .focusIndicator;
    }
}

.descInput {
    width: 130px;
}

.iconInput {
    display: none;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;