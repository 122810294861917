@import (reference) '../../variables';

.selectWrapper {
  position: relative;
  flex: 1;
  max-width: 700px;

  :global(.ant-select-selection-placeholder) {
    .standardMotion;
    color: @colorTextMuted;
  }
}

.label {
  .standardMotion;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  color: @colorText;
  font-size: 90%;
  pointer-events: none;
}

.active {
  :global(.ant-select-selection__placeholder) {
    opacity: 0;
    transform: translateY(-20px);
    font-size: 90%;
  }
}
.select {
  width: 100%;
}

.clearBtn {
  opacity: 0.5;
  border: none;
  background-color: transparent;
  .h4Size;

  &:hover,
  &:focus {
    opacity: 1;
    background-color: transparent;
  }
}
.avatarContainer {
  display: flex;
  flex-flow: row nowrap;
}

.avatarName {
  margin-left: 5px;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;