@import (reference) '../../variables';

.wrapper {
  position: relative;

  input::placeholder {
    .standardMotion;
    color: @colorTextMuted;
  }

  textarea::placeholder {
    .standardMotion;
    color: @colorTextMuted;
  }
}

.label {
  .standardMotion;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  color: @colorTextAlt;
  font-size: 90%;
  pointer-events: none;

  &.labelDark {
    color: @colorText;
  }
}

.active {
  &.large {
    input::placeholder {
      transform: translateY(-18px);
    }

    .label {
      top: -18px;
    }
  }

  input::placeholder {
    opacity: 0;
    transform: translateY(-15px);
    font-size: 90%;
  }

  .label {
    top: -15px;
    opacity: 0.7;
    pointer-events: unset;
    line-height: 1;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;