@import (reference) '../../../variables';

.container {
  :global(.ant-calendar-picker) {
    width: 100%;
  }

  :global(.ant-form-item) {
    margin-bottom: @spaceXSmall;

    @media @sm {
      margin-bottom: @spaceSmall;
    }

    @media @xs {
      margin-bottom: 33px;
    }
  }

  :global(.ant-form-item-label) {
    @media @xs {
      padding-bottom: 2px;
    }
  }
}

.switchText {
  margin-left: @spaceXSmall;
}

.alertWrapper {
  margin-top: @spaceXSmall;
  max-width: @contentWidthSmall;

  &.hidden {
    display: none;
  }
}

.explainText {
  .helpText;
  margin-top: @spaceXSmall;
}

.showDetails {
  margin-bottom: @spaceSmall;
  cursor: pointer;
  color: @colorPrimary;
  display: inline-block;

  &:hover {
    color: @colorActive;
  }
}

.checkbox {
  margin-left: @spaceXSmall;

  :global(.ant-checkbox-disabled + span) {
    color: @textLight;
  }
}

.sendBtn {
  margin-top: @spaceSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;