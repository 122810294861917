@import (reference) '../../variables';

.container {
  margin: @spaceXSmall 0;
  max-width: @contentWidthSmall;
}

.buttonsBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  @media @xs {
    flex-direction: column-reverse;
    gap: @spaceXSmall;
  }
}

.cancelButton {
  margin-right: @spaceXSmall;

  @media @xs {
    margin-right: 0;
  }
}

.commentTextInput {
  margin-bottom: @spaceXSmall;
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;