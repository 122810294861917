@import (reference) '../../../variables';

.container {
    display: flex;
    flex-direction: column;
}

.inputsWrapper {
    display: flex;

    @media @md {
      justify-content: space-between;
    }

    @media @sm {
      flex-direction: column;
    }
}

.localizationWrapper {
  margin-left: @spaceXSmall;

  @media @sm {
    margin-left: 0;
  }
}

.optionalInputWrapper {
  display: flex;
  margin-right: @spaceSmall;
}

.deleteButton {
  margin-top: @spaceSmall;
}

.buttonsWrapper {
    display: flex;
    margin-top:  @spaceXSmall;

    @media @xs {
      flex-direction: column;
      gap: @spaceXSmall;
    }
}

.input {
    margin-top:  @spaceSmall;
    margin-right: 3px;
    width: 400px;

    @media @md {
      width: 320px;
    }

    @media @sm {
      width: 100%;
    }
}

.textarea {
    margin: 25px 0 0 0;
    max-width: 850px;

    @media @md {
      max-width: 100%;
    }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;