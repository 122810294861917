@import (reference) '../../../variables';

@signUpBoxBorderColor: @colorTextAltMuted;

.container {
  .textSize;
  .authenticationBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global(.ant-row.ant-form-item) {
    margin-bottom: 35px;
  }

  :global(.ant-row.ant-form-item.ant-form-item-with-help) {
    margin-bottom: @spaceXSmall;
  }

  :global(.ant-form-explain) {
    margin-top: 4px;
  }

  :global(.has-error .ant-form-explain) {
    color: @invertedErrorColor;
  }
}

.content {
  width: 600px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.promptTitle {
  .h2Size;
  text-align: left;
  color: @colorTextAlt;
  margin: 0;
  padding: 0 @space;
  font-weight: 300;
  margin-bottom: @space;

  @media @xs {
    text-align: center;
    padding: 0 @spaceSmall;
  }
}

.promptMessage {
  .h4Size;
  text-align: left;
  color: @colorTextAlt;
  margin: 0;
  padding: 0 @space;
  font-weight: 300;
  margin-bottom: @space;

  @media @xs {
    text-align: center;
    padding: 0 @spaceSmall;
  }
}

.logo {
  width: 150px;
  margin-bottom: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;