@import (reference) '../../variables';

.drawer {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  pointer-events: none;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transform: translateX(~'calc(100% + 30px)'); // hide drawer & close button
  transition: transform 200ms @standardMotion;
}

.visible {
  &.drawer {
    pointer-events: initial;
  }

  .contentWrapper {
    transform: translateX(0);
  }
}

.content {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
}

.close {
  box-shadow: -2px 1px 3px 0 rgba(0, 0, 0, 0.25);
  left: -20px;
  top: 50vh;
  background: white;
  border-radius: 50%;
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 1001;
  border: 0;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: @colorTextMuted;
  .standardMotion;

  &:hover {
    color: @colorText;
  }

  &:focus-visible {
    .focusIndicator;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;