@import (reference) '../../variables';

.uploadText {
  display: inline-block;
  max-width: 90%;
  margin: 0 auto;
}

.dropzone {
  :global(.ant-upload) {
    display: none;
  }

  &:focus-within {
    .focusIndicator;
  }
}

.dropzone + div {
  :global(.ant-upload-list-item-card-actions) {
    & button:focus-visible {
      .focusIndicator;
    }
  }

  :global(.ant-upload-list-item-name) {
    &:focus-visible {
      .focusIndicator;
    }
  }
}

.visible,
.dragged {
  :global(.ant-upload) {
    display: block;
  }
}

.miniDropzone {
  &:not(.dragged) {
    :global(.ant-upload) {
      padding: 3px;
      opacity: 0.7;
    }

    .dragIcon {
      margin-bottom: 0px !important;

      :global(.anticon) {
        font-size: 20px !important;
      }
    }

    .uploadMessage {
      font-size: 12px !important;
    }

    .uploadHint {
      font-size: 0 !important;
      color: @purple900 !important;
    }
  }
}

.candidateDropzone {
  display: none;
}

.candidateDropzone.visible,
.candidateDropzone.dragged {
  display: block;

  &:focus-within {
    .focusIndicator;
  }
}


@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;