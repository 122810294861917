@import (reference) '../../../variables';

.input {
    margin: @spaceSmall;
    width: 400px;

    @media @xs {
        width: 90%;
    }
}

.accordion {
    max-width: 600px;
    margin-bottom: @spaceXSmall;

    :global(.ant-collapse-header) {
        background-color: @white;
    }

    :global(.ant-collapse-item-active .ant-collapse-header) {
        border-bottom: none;
    }

    :global(.ant-collapse-content) {
        border-top: none;
    }
}

.panel {
    &:hover {
        .removeBtn {
            opacity: 1;
        }
    }
}

.panelHeader {
    display: flex;
    align-items: center;
    cursor: move;
    transition: all 0.3s @standardMotion;
    position: relative;

    &:hover {
        background-color: @gray100;
    }

    &.dragging {
        opacity: 0.5;
    }

    &.placeholder {
        opacity: 0.7;
        background-color: @colorBackgroundHighlight;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
        border-radius: 4px;
    }
}

.dragHandle {
    margin-right: @spaceXSmall;
    color: @colorTextMuted;
}

.removeBtn {
    background: transparent;
    margin-top: @spaceXSmall;
    opacity: 0;
    
    &:hover {
        background: transparent;
    }
}

.itemInput {
    width: 400px;
    margin: 0;
    margin-left: 40px;

    @media @xs {
        width: 100%;
        margin-left: 0;
    }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;