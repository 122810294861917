@import (reference) '../../variables';

.content {
  padding: @space;
  background: #fff;
  box-shadow: 0 0 3px @colorShadowLight;
  border-radius: 3px;

  @media @xs {
    padding: @spaceXSmall;
  }

  &.isTransparent {
    background: transparent;
    padding: 0.1px 0; // don't sum up margins
    box-shadow: none;
  }

  &.isCondensed {
    padding: 0;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;