@import (reference) '../../../variables';

.details {
  .textSizeSmall;
  display: flex;
  flex-direction: row;
  color: @colorTextMuted;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.detail {
  display: block;
  margin-right: @space;

  @media @xs {
    margin-right: @spaceSmall;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.remoteTag {
  .textSizeSmall;
}

.noRequestText {
  .helpText;
}

.showMoreButton {
  border: none;
  padding: 0;
  background-color: transparent;
  color: @colorPrimary;
  box-shadow: none;
  margin-top: @spaceXSmall;

  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
}

.alert {
  margin-top: @spaceXSmall;
  max-width: @contentWidthSmall;

  &:global(.ant-alert) {
    padding: @spaceSmall 0;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;