@import (reference) '../../../../variables';

.stageSelect {
  width: 150px;
  position: relative;

  &.cardStageSelect {
    @media @xs {
      font-size: 12px;
      width: 140px;
    }
  }
}

.label {
  line-height: 1;
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: center;
  text-align: left;
}

.stageDots {
  margin-top: -1px;
  margin-bottom: -1px;
}

.dot {
  display: inline-block;
  opacity: 0.2;
  background: @colorTextSecondary;
  width: 5px;
  height: 5px;
  margin-right: 3px;
  border-radius: 50%;

  &.filled {
    background: @colorTextSecondary;
    border: none;
    opacity: 0.6;
  }
}

.dropdownFooter {
  display: flex;
  align-items: center;
  padding: @spaceXSmall @spaceXSmall;
  border-top: 1px solid @colorDivider;
  background: @colorBackgroundLightGray;

  :global(.ant-checkbox-wrapper) {
    color: @orchid800;
  }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;