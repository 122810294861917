@import (reference) '../../../../../variables';

.previewContainer {
  padding: 0 @spaceXSmall;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.infoAlert {
  margin-bottom: @spaceSmall;
  color: @gray700;
  .textSizeSmall();
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255, 0.6);
}

.boldField {
  font-weight: bold;
}

.nextButton { 
  padding: 30px 0 30px 10px;
  font-size: 20px;

  &[disabled] {
    cursor: auto;
    color: @gray300;
  }
}

.previousButton { 
  padding: 30px 10px 30px 0;
  font-size: 20px;

  &[disabled] {
    cursor: auto;
    color: @gray300;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;