@import (reference) '../../variables';

.isLarge,
.isSmall,
.isMedium {
  margin-left: auto;
  margin-right: auto;
}

.isLarge {
  max-width: @layoutWidthLarge;
}

.isMedium {
  max-width: @layoutWidthMedium;
}

.isSmall {
  max-width: @layoutWidthSmall;
}

.secondary {
  margin-top: @spaceLarge;

  .title {
    .h4Size();
  }
}

.header {
  margin-bottom: @spaceXSmall;

  @media @xs {
    padding: 0 @spaceXSmall;
  }
}

.withHeaderSpacing {
  margin-bottom: @spaceSmall;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  margin: 0;
  .h3Size();
}

.spacer {
  flex: 1;
}

.actions {
  display: flex;
  justify-content: space-between;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;