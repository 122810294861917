@import (reference) '../../../../variables';

.templateWrapper {
  max-width: @contentWidth;
  margin-bottom: @spaceSmall;
  position: relative;

  &:hover {
    .menuWrapper {
      opacity: 1;
    }
  }
}

.templateContainer {
  .tileContentWrapper;
}

.templateTitleBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: @spaceSmall;
}

.templateTitle {
  .textSizeLarge;
  font-weight: 500;
  flex: 1;
  margin-right: @spaceXSmall;
}

.menuWrapper {
  position: absolute;
  right: 15px;
  top: 10px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &:focus-within {
    opacity: 1;
  }
  .standardMotion;

  @media @xs {
    right: 10px;
  }
}

.icon {
  font-size: 20px;
  cursor: pointer;
  .standardMotion;

  &.iconEdit {
    margin-right: 15px;

    &:hover {
      color: @colorPrimary;
    }
  }

  &.iconDelete {
    &:hover {
      color: @colorDanger;
    }
  }
}

.templateText {
  .textSize;
  color: @colorText;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;