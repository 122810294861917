@colorHint: rgba(0, 0, 0, 0.6);
@sizeHint: 12px;

.hint() {
  color: @colorHint;
  font-size: @colorHint;
}

// Palette
// Try not to lode those colors directly, use color scheme
@white: #fff;
@white100: #fdfdfd;

@gray100: #f6f8f9;
@gray200: #e2e7ec;
@gray300: #cbd4df;
@gray400: #b9c5d5;
@gray500: #a8b6c8;
@gray600: #586069;
@gray700: #373d44;
@gray800: #282d33;
@gray900:  #000029;


@orchid900: #8a417e;
@orchid800: #aa5397;
@orchid600: #BB73AB;
@orchid500: #CD97C1;
@orchid200: #EBDDE5;

@purple900: #4f2452;
@purple800: #69306D;
@purple500: #A583A7;
@purple100: #EAE3EB;

@yellow: #FEB437;
@red: #CF2B2B;
@blue900: #141440;
@green: #21823C;

@textLight: #727283;
@backgroundLight: #fbfafb;

// Color scheme
@bg: @backgroundLight;
@bgAlt: @purple800;
@bgAltLight: @orchid500;
@bgAltDark: @purple900;
@bgAltMuted: @gray700;
@bgAltMutedDark: @gray800;
@antdBorder: #d9d9d9;
@antDisabled: #bababa;
@antTextColor: #4a4a64;
@tableSelectedRow: #fafafa;
@colorMenuUnderline: @white;
@colorPrimary: @orchid800;
@colorActive: @orchid600;
@colorActiveLight: lighten(@orchid600, 45%);
@colorActiveDark: lighten(@orchid600, 20%);
@colorText: @blue900;
@colorTextSecondary: @purple800;
@colorTextMuted: @textLight;
@colorDarkGray: rgba(7, 6, 6, 0.6);
@colorGray: rgba(0, 0, 0, 0.25);;
@colorLightGray: rgba(0, 0, 0, 0.1);
@colorLighterGray: rgba(0, 0, 0, 0.05);
@colorTextAlt: @white;
@colorTextAltMuted: rgba(255, 255, 255, 0.7);
@colorShadow: rgba(0, 0, 0, 0.25);
@colorShadowLight: rgba(0, 0, 0, 0.1);
@colorBackgroundGray: @gray100;
@colorBackgroundLightGray: @white100;
@colorBackgroundHighlight: @purple100;
@invertedErrorColor: #ff6068;
@colorDanger: rgb(253, 82, 85);
@colorDangerBackground: rgb(253, 241, 240);
@warningColor: #faad14;
@colorPopoverDelete: #fefefe;
@colorBorder: @colorLightGray;
@colorDivider: @gray200;

// Background for login/logout/register etc
.authenticationBackground {
  background-color: @blue900;
  min-width: 100vw;
  min-height: 100vh;
}

// Remove link styling
.removeLinkStyling {
  cursor: initial;
  color: initial;

  &:hover {
    color: initial;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    color: initial;
  }
}

// Help text
.helpText {
  color: @colorTextMuted;
  font-style: italic;
}

// Media queries
@xs: ~'only screen and (max-width: 575px)';
@sm: ~'only screen and (max-width: 767px)';
@md: ~'only screen and (max-width: 991px)';
@lg: ~'only screen and (max-width: 1199px)';
@xl: ~'only screen and (max-width: 1599px)';

// Sizing
.textSizeLarge() {
  font-size: 16px;

  @media @xs {
    font-size: 14px;
  }
}

.textSize() {
  font-size: 14px;

  @media @xs {
    font-size: 12px;
  }
}

.textSizeSmall() {
  font-size: 12px;

  @media @xs {
    font-size: 10px;
  }
}

.h2Size() {
  font-size: 38px;

  @media @xs {
    font-size: 32px;
  }
}

.h3Size() {
  font-size: 30px;

  @media @xs {
    font-size: 26px;
  }
}

.h4Size() {
  font-size: 20px;

  @media @xs {
    font-size: 18px;
  }
}

.h5Size() {
  font-size: 18px;

  @media @xs {
    font-size: 16px;
  }
}

@spaceXSmall: 10px;
@spaceSmall: 20px;
@space: 30px;
@spaceLarge: 50px;
@spaceXLarge: 100px;

@contentWidthLarge: 1000px;
@contentWidth: 800px;
@contentWidthSmall: 600px;

@layoutWidthLarge: 1600px;
@layoutWidthMedium: 1200px;
@layoutWidthSmall: 800px;

// Published Job Request

.published {
  filter: grayscale(60%);
  opacity: 0.4;
}

// Standard motion

@standardMotion: cubic-bezier(0.645, 0.045, 0.355, 1);

.standardMotion {
  transition: all 0.2s @standardMotion;
}

// Table Name Cell title
.nameCellTitle {
  .textSizeLarge;
  .standardMotion;
  cursor: pointer;
  white-space: normal;
  color: @colorPrimary;

  &:hover {
    color: @colorActive;
  }

  @media @xs {
    .h5Size;
  }
}

// card / tile data display - use as wrapper for text content
.tileContentWrapper {
  background-color: @white100;
  border-radius: 10px;
  padding: 15px 40px 20px 20px;
  box-shadow: 0px 2px 4px @colorShadowLight;

  @media @xs {
    border-radius: 0;
    padding-right: @space;
  }
}

// interactive elements - focus indicator on :focus-visibility
.focusIndicator {
  outline: none;
  border: 2px solid @purple800;
  transition: none;
}
