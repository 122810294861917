.drawer {
  :global(.ant-drawer-close) {
    box-shadow: -2px 1px 3px 0 rgba(0, 0, 0, 0.25);
    left: -20px;
    top: 50vh;
    background: white;
    border-radius: 50%;
  }

  :global(.ant-drawer-close-x) {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;

    &::before {
      content: '\E61F';
    }
  }

  &:global(.ant-drawer-right.ant-drawer-open) {
    width: initial;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;