@import (reference) '../../../../variables';

.draggingBackground {
    background-color: rgba(254, 180, 55, 0.15);
    transition: background-color 0.3s;
}

.stage {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 1fr;
    align-content: center;
    padding: @spaceXSmall 0;
    
    &:hover{
       .draggingBackground;

        & .deleteButton {
            background-color: rgba(254, 180, 55, 0.06);
        }
    }

    &:focus {
        .focusIndicator;
    }
}

.dragging {
    .draggingBackground;

    & .deleteButton {
        background-color: rgba(254, 180, 55, 0.06);
    }
}

.draggingIcon {
    color: @colorGray;
    padding: 0 4px;
    border: 1px solid @colorGray;
    border-radius: 16px;
    width: 24px;
    height: 24px;
    align-self: center;
}

.inputField {
    align-self: center;
    margin: 0;
}

.selectField {
    align-self: center;
    margin: 0 0 0 5px;
}

.addButton {
    margin-top: @spaceXSmall;
    & .icon {
        font-size: 12px;
        color: @colorGray;
        &:hover {
            color: @yellow;
        }
    }
}

.infoAlert {
    margin-bottom: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;