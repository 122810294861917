@import (reference) '../../../../variables';

.pickerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iconsWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    max-height: 300px;
    overflow-y: auto;
}

.iconButton {
   cursor: pointer;
   background-color: @bg;
   border: 1px solid @colorLightGray ;
   border-radius: 3px;
   text-align: center;
   height: 50px;
   width: 50px;

   &:hover {
     background-color: @colorBackgroundHighlight;
     border: 2px solid @colorLightGray;
   }

   &:focus-visible {
     .focusIndicator;
   }
}

.selectedIcon {
  display: flex;
  gap: @spaceXSmall;
  align-items: center;
}

.searchInput {
  width: 435px;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;