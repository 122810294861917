@import (reference) '../../../variables';

.applicationsCount, .subscription {
  text-align: center;
}

.centeredHeader {
  text-align: center !important;
}

.noRequestText {
  .helpText;
  .textSizeSmall;
  text-align: center;
}

.createdAtWrapper {
  text-align: center;
}

.createdAt {
  .textSizeSmall;
  color: @colorTextMuted;

  @media @xs {
    font-size: 12px;
  }
}

.subtitle {
  margin-top: @spaceXSmall;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;