@import (reference) '../../variables';

@mini: 18px;

.avatar {
  &.mini {
    font-size: 9px;
    width: @mini;
    height: @mini;
    line-height: @mini;

    > * {
      line-height: @mini;
    }
  }

  &.small {
    font-size: 12px;
  }

  :global(.ant-avatar-string) {
    transform: scale(1) !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.gravatar {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;