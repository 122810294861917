@import (reference) '../../variables';

.container {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: @gray100;
}

.planned,
.progress {
  border-radius: 4px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 0;
}

.progress {
  background: @colorPrimary;
}

.planned {
  border: 1px solid @colorPrimary;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;