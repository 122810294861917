@import (reference) '../../variables';

.emailStatus {
  position: relative;
  z-index: 3;
  margin-left: 3px;
}

.resendBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: @spaceXSmall 0 @spaceSmall;
  padding: @spaceXSmall @spaceSmall;

  :global(.ant-alert-icon) {
    position: initial;
    margin-right: @spaceSmall;
  }
}

.resendTextButton {
  .textSizeSmall;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media @xs {
    flex-direction: column;
  }
}

.resendButton {
  .textSizeSmall;
  margin-left: @spaceXSmall;

  @media @xs {
    margin: @spaceXSmall 0 0;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;