@import (reference) '../../variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: @space;

  @media @lg {
    justify-content: space-around;
  }

  @media @sm {
    flex-direction: column-reverse;
  }

  @media @xs {
    margin-top: @spaceSmall;
  }
}

.image {
  max-width: 100%;
}

.textContainer {
  text-align: center;
  margin-left: @space;

  @media @lg {
    margin-left: 0;
  }

  @media @sm {
    margin-left: 0;
    margin-bottom: @spaceLarge;
  }

  @media @xs {
    margin-bottom: @space;
  }
}

.number {
  .h2Size;
}

.text {
  .textSizeLarge;
}

.link {
  &:focus-visible {
    .focusIndicator;
  }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;