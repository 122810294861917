@import (reference) '../../variables';

.modalContainer {
  &.bodyPrimary {
    :global(.ant-modal-body) {
      background-color: @colorActiveLight;
    }
  }

  &.bodyDanger {
    :global(.ant-modal-body) {
      background-color: @colorDangerBackground;
    }
  }

  :global(.ant-modal-footer) {
    & button:focus-visible {
      .focusIndicator;
    }
  }
}

.unverifiedAlert {
  margin-bottom: @spaceSmall;
}

.changePlanToText {
  .textSizeLarge;
  display: flex;
  justify-content: space-between;
}

.changePlanToCost {
  color: @colorTextMuted;
}

.changePlanToInfo {
  .textSizeSmall;
  color: @colorTextMuted;
}

.changePlanAlertWrapper {
  margin-top: @spaceSmall;
}

.infoContainer {
  text-align: center;
}

.infoIcon {
  .h2Size;

  &.primary {
    color: @colorPrimary;
  }

  &.danger {
    color: @colorDanger;
  }
}

.infoTitle {
  .h4Size;
}

.infoText {
  .textSize;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;