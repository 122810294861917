@import (reference) '../../../variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input {
  margin: @spaceXSmall 0 @spaceSmall;
  width: 400px;

  @media @xs {
    width: 90%;
  }
}

.iconContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: @spaceXSmall;
  min-height: 100px;
  gap: @spaceXSmall;
}

.iconWrapper {
  cursor: move;
  transition: all 0.3s @standardMotion;
  position: relative;

  &:hover {
    transform: scale(1.05);
  }

  &.dragging {
    opacity: 0.5;
  }

  &.placeholder {
    opacity: 0.7;
    background-color: @colorBackgroundHighlight;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    border-radius: 4px;
  }
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;