@import (reference) '../../../../variables';

.header {
    display: flex;
    align-items: center;
    gap: 5px;
}

.iconBtn {
    border: none;
    margin: 0;
    padding: 0;
    background: inherit;
    color: @textLight;
    cursor: pointer;

    &:focus-visible {
        .focusIndicator;
    }
}

.itemInputHeader {
    width: 400px;
    margin: 0;
    input {
        font-weight: 600;
    }

    @media @xs {
        width: 140px;
    }
}

.iconInput {
    display: none;
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;