@import (reference) "../../variables";

.activeFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: @spaceXSmall 0 5px;
}

.divider {
  border: 0.5px solid @colorDivider;
  margin: 0;
}

.filtersBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;

  @media @xs {
    justify-content: center;
  }
}

.filter {
  min-width: 200px;
  margin: 10px 10px 0 0;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
}

.clearFiltersButton {
  padding: 0;
  margin-left: @spaceXSmall;
}

.button {
  color: @colorTextMuted;         
}

.customFieldsWrapper {
  height: 225px;
  overflow: scroll;
}

.filterTag {
  background-color: @colorPrimary;
  border-color: @colorPrimary;
  color: @white;
  :global(.ant-tag-close-icon) {
    color: inherit;
  }
  margin: 3px;
}
@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;