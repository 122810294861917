@import (reference) '../../variables';

.bodyTitle {
  margin-bottom: @spaceXSmall;
}

.listTitle {
  margin-bottom: @spaceXSmall;
  font-weight: bold;
}

.list {
  padding-left: @spaceSmall;
  margin-bottom: 0;
  list-style: none;
}

.listIcon {
  margin-right: @spaceXSmall;

  &.iconPrimary {
    color: @colorPrimary;
  }

  &.iconDanger {
    color: @invertedErrorColor;
  }
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

@border-radius-base: 6px;@border-radius-sm: 3px;@layout-body-background: #fff;@icon-url: /static/iconfont/iconfont;@primary-color: #aa5397;@processing-color: #aa5397;@heading-color: #141440;@text-color: #4A4A64;@text-color-secondary: #69306D;@normal-color: #E7E7E9;@btn-border-radius-base: 50px;@btn-primary-color: #141440;@btn-primary-bg: #FEB437;@btn-shadow: none;@btn-primary-shadow: none;@input-addon-bg: #fff;